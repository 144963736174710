import DyteVideoBackgroundTransformer from '@dytesdk/video-background-transformer';
import { useEffect, useRef } from 'react';
import { logger } from '../utils/logger';
export function useBlurring(meeting, blurred) {
    const transformerRef = useRef();
    const middleware = useRef();
    useEffect(() => {
        if (DyteVideoBackgroundTransformer.isSupported() &&
            !transformerRef.current) {
            transformerRef.current = DyteVideoBackgroundTransformer.init().catch((e) => {
                logger(e, 'Error while initializing DyteVideoBackgroundTransformer', {
                    level: 'trace',
                });
                return undefined;
            });
        }
        else {
            logger('DyteVideoBackgroundTransformer is not supported');
        }
        // ensure that the transformer is initialized only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Create the middleware when we have the transformer
    useEffect(() => {
        if (!transformerRef.current) {
            return;
        }
        transformerRef.current
            .then((transformer) => {
            if (!transformer) {
                return;
            }
            transformer
                .createBackgroundBlurVideoMiddleware()
                .then((m) => {
                middleware.current = m;
            })
                .catch((e) => {
                logger(e, 'Error while creating video blur middleware:', {
                    level: 'error',
                });
            });
        })
            .catch((e) => {
            logger(e, 'Error initializing video transformer', {
                level: 'error',
            });
        });
        // ensure that the middleware is created only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Apply the blur effect when needed and we have a middleware
    useEffect(() => {
        if (!middleware.current) {
            return;
        }
        if (blurred) {
            meeting.self
                .addVideoMiddleware(middleware.current)
                .catch((error) => logger(error, 'Error while adding video blur middleware:', {
                level: 'error',
            }));
        }
        else {
            meeting.self.removeVideoMiddleware(middleware.current).catch((error) => logger(error, 'Error while removing video blur middleware:', {
                level: 'error',
            }));
        }
        return () => {
            if (!middleware.current) {
                return;
            }
            meeting.self.removeVideoMiddleware(middleware.current).catch((error) => logger(error, 'Error while removing video blur middleware:', {
                level: 'error',
            }));
        };
        // keep middleware.current in the dependencies to avoid stale closure
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blurred, meeting.self, middleware.current]);
}
