import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useActiveSpeaker } from './useActiveSpeaker';
// Decides if a DyteClient is the host of the meeting based on the first character in the customParticipantId. (H means host)
export const isHost = (participant) => {
    var _a;
    return ((_a = participant.customParticipantId) === null || _a === void 0 ? void 0 : _a[0]) === 'H';
};
// Returns (first) host participant, active speaker, and last speaker who is not the host/active one
export function useMainSpeakers() {
    const { meeting } = useDyteMeeting();
    const joinedParticipants = useDyteSelector((m) => m.participants.joined.toArray());
    const participantCount = useDyteSelector((m) => m.participants.joined.size + (m.self.roomJoined ? 1 : 0));
    const [host, setHost] = useImmer(undefined);
    const [speaker] = useActiveSpeaker();
    const [thirdSpeaker, setThirdSpeaker] = useImmer(undefined);
    // initialize host for patrons
    useEffect(() => {
        const initialHost = joinedParticipants.find(isHost);
        if (initialHost) {
            setHost(initialHost);
        }
        else if (isHost(meeting.self)) {
            setHost(meeting.self);
        }
    }, [joinedParticipants, meeting.self, setHost]);
    // initialize third speaker in event there are more than 2 participants for patrons
    useEffect(() => {
        if (!thirdSpeaker && participantCount === 3) {
            // Find the last participant that is not a host
            const lastNonHostParticipant = joinedParticipants
                .filter((participant) => participant.id !== (host === null || host === void 0 ? void 0 : host.id))
                .pop();
            if (lastNonHostParticipant) {
                setThirdSpeaker(lastNonHostParticipant);
            }
        }
    }, [
        host === null || host === void 0 ? void 0 : host.id,
        joinedParticipants,
        participantCount,
        setThirdSpeaker,
        thirdSpeaker,
    ]);
    // set third speaker to active speaker
    useEffect(() => {
        if (speaker && speaker.id !== meeting.self.id && speaker.id !== (host === null || host === void 0 ? void 0 : host.id)) {
            setThirdSpeaker(speaker);
        }
    }, [host === null || host === void 0 ? void 0 : host.id, meeting.self.id, setThirdSpeaker, speaker]);
    useEffect(() => {
        const participantJoined = (participant) => {
            // Set the host if there is no host and the joining participant is a host
            if (!host && isHost(participant)) {
                setHost(participant);
            }
        };
        const participantLeft = (participant) => {
            let newHost = joinedParticipants.find(isHost);
            if (isHost(participant)) {
                if (!host) {
                    // Prioritize setting newHost to meeting.self if it's a host and newHost is not already set
                    if (!newHost && isHost(meeting.self)) {
                        newHost = meeting.self;
                    }
                    // If still no newHost, default to the current participant
                    if (!newHost) {
                        newHost = participant;
                    }
                    // Finally, set the host
                    setHost(newHost);
                }
            }
            if ((thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id) === participant.id ||
                (thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id) === (newHost === null || newHost === void 0 ? void 0 : newHost.id) ||
                (thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id) === meeting.self.id) {
                setThirdSpeaker(undefined);
            }
        };
        // Set up event listeners, to monitor participant actions
        meeting.participants.joined.on('participantJoined', participantJoined);
        meeting.participants.joined.on('participantLeft', participantLeft);
        // Remove event listeners on unmount
        return () => {
            meeting.participants.joined.off('participantJoined', participantJoined);
            meeting.participants.joined.off('participantLeft', participantLeft);
        };
        // To be run only once to set up listeners
    }, [
        host,
        joinedParticipants,
        meeting.participants.joined,
        meeting.self,
        setHost,
        setThirdSpeaker,
        thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id,
    ]);
    return [host, thirdSpeaker];
}
